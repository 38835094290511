
.carousel {
  width:80%;
  height:auto;
  margin: auto;
  margin-Top: 50px;
  margin-Bottom: 50px;

}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  filter: invert(1) sepia(1) saturate(5) hue-rotate(90deg);
}

.schedule-table-container {
  width: 100%;
  overflow-x: auto;
}

.schedule-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}

.schedule-table th,
.schedule-table td {
  padding: 10px 20px;
  text-align: left;
  border: 1px solid #e0e0e0;
}

.schedule-table thead {
  background-color: #f5f5f5;
}

.schedule-table tbody tr:nth-child(even) {
  background-color: #fafafa;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 10px;
  padding: 20px;
}

.modal h2 {
  margin-top: 0;
}

.modal-actions {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
